import api from '../../config/axios.js';

const state = {
    realtime: [],
    cardsRealtime: {
        total_success: 0,
        total_error: 0,
        total_time: 0,
        total_size: 0
    },
    timers: {
        "0_5": 0,
        "5_10": 0,
        "10_15": 0,
        "15_20": 0,
        "bigger": 0,
    },
    metricsTotals: {
        total_success: 0,
        total_error: 0,
        total_time: 0,
        total_size: 0
    },
    realtimeChannel: "",
    metricsTotalsFinish: true
}
const getters = {
    getRealtime: state => state.realtime,
    getCardsRealtime: state => state.cardsRealtime,
    getTimers: state => state.timers,
    getMetricsTotals: state => state.metricsTotals,
    getRealtimeChannel: state => state.realtimeChannel,
    getMetricsTotalsFinish: state => state.metricsTotalsFinish
}
const actions = {
    // SETANDO OS LOGS ATUAIS
    async setNewLog({ commit }, log){
        commit('setNewLog', log);
    },
    // SETANDO OS ULTIMOS 5 LOGS
    async setLastLogs({ commit }){
        const token = localStorage.getItem("secretadmirertoken");
        if(token != undefined && token != null && token){
            await api.axios.get('/api/latest/logs', {headers: { Authorization: `Bearer ${token}` }}).catch(function(error){
                // console.log("erro ao recuperar logs", error);
            }).then(function(response){
                if(response.status == 200){
                    commit('setLastLogs', response.data);
                }
            });
        } else {
            window.location.href = "/login";
        }
    },
    // SETANDO CANAIS DO REALTIME
    async setRealtimeChannel({ commit }){
        const token = localStorage.getItem("secretadmirertoken");
        if(token != undefined && token != null && token){
            await api.axios.get('/api/realtime/channel', {headers: { Authorization: `Bearer ${token}` }}).catch(function(error){
                // console.log("erro ao recuperar canal do realtime", error);
            }).then(function(response){
                if(response.status == 200 && response.data.channel){
                    commit('setRealtimeChannel', response.data.channel);
                }
            });
        } else {
            window.location.href = "/login";
        }
    },
    // SETANDO OS LOGS ATUAIS PARA OS CARDS
    async setLastMetricsCard({ commit }){
        const token = localStorage.getItem("secretadmirertoken");
        if(token != undefined && token != null && token){
            await api.axios.get('/api/latest/cards', {headers: { Authorization: `Bearer ${token}` }}).catch(function(error){
                // console.log("erro ao recuperar logs para os cards", error);
            }).then(function(response){
                if(response && response.status == 200){
                    commit('setLastMetricsCard', response.data);
                }
            });
        } else {
            window.location.href = "/login";
        }
    },
    setNewStatusAddSuccess({ commit }, data){
        // console.log("sucesso");
        commit('setNewStatusAddSuccess', data);
    },
    setNewStatusAddError({ commit }, data){
        // console.log("erro");
        commit('setNewStatusAddError', data);
    },
    // SETANDO OS LOGS ATUAIS PARA OS CARDS
    async setLastMetricsTimers({ commit }){
        const token = localStorage.getItem("secretadmirertoken");
        if(token != undefined && token != null && token){
            await api.axios.get('/api/metrics/timers', {headers: { Authorization: `Bearer ${token}` }}).catch(function(error){
                // console.log("erro ao recuperar logs de timers", error);
            }).then(function(response){
                if(response && response.status == 200){
                    commit('setLastMetricsTimers', response.data);
                }
            });
        } else {
            window.location.href = "/login";
        }
    },
    // SETANDO OS LOGS ATUAIS PARA OS CARDS
    async setMetricsTotals({ commit }){
        const token = localStorage.getItem("secretadmirertoken");
        if(token != undefined && token != null && token){
            commit("setMetricsTotalsFinish", false);
            await api.axios.get('/api/metrics/total', {headers: { Authorization: `Bearer ${token}` }}).catch(function(error){
                // console.log("erro ao recuperar logs totais", error);
            }).then(function(response){
                if(response && response.status == 200){
                    commit('setMetricsTotals', response.data);
                }
                commit("setMetricsTotalsFinish", true);
            });
        } else {
            window.location.href = "/login";
        }
    }
}
const mutations = {
    // SETANDO OS LOGS ATUAIS
    setNewLog: function(state, log){
        if(state.realtime.length >= 5){
            state.realtime.shift();
            state.realtime.push(log);
        } else {
            state.realtime.push(log);
        }
        
    },
    // SETANDO OS LOGS ATUAIS
    setLastLogs: function(state, data){
        state.realtime = data;        
    },
    // SETANDO CANAIS DO REALTIME
    setRealtimeChannel: function(state, data){
        state.realtimeChannel = data;
    },
    // SETANDO OS LOGS ATUAIS PARA OS CARDS
    setLastMetricsCard: function(state, data){
        state.cardsRealtime = data;        
    },
    setNewStatusAddSuccess: function(state, data){
        state.cardsRealtime.total_success += 1;
        state.cardsRealtime.total_time = parseFloat(state.cardsRealtime.total_time) + data.original_time;
        state.cardsRealtime.total_size = parseFloat(state.cardsRealtime.total_size) + data.original_size;

        if(data.original_time < 300){
            state.timers["0_5"] += 1;
        }

        if(data.original_time >= 300 && data.original_time < 600){
            state.timers["5_10"] += 1;
        }

        if(data.original_time >= 600 && data.original_time < 900){
            state.timers["10_15"] += 1;
        }

        if(data.original_time >= 900 && data.original_time < 1200){
            state.timers["15_20"] += 1;
        }

        if(data.original_time >= 1200){
            state.timers["bigger"] += 1;
        }
    },
    setNewStatusAddError: function(state){
        state.cardsRealtime.total_error += 1;
        state.timers["0_5"] += 1;
    },
    setLastMetricsTimers: function(state, data){
        state.timers = data;
    },
    setMetricsTotals: function(state, data){
        state.metricsTotals = data;
    },
    setMetricsTotalsFinish: function(state, data){
        state.metricsTotalsFinish = data;
    }
}
export default {
    state,
    getters,
    actions,
    mutations
}