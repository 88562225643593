<template>
  <div class="row justify-content-center">
    <div class="col-md-10 col-lg-8 col-xl-7 col-xxl-7 col-xxxl-7 maxWidthContainer">
      <div class="card border-0 rounded-0 bg-white authentication-card">
        <div class="card-body letter-spacing col-12">
          <div class="d-flex align-items-center flex-column">
            <img class="me-2" src="../../assets/images/favicon.png" width="80" alt="Layer">
            <h4 class="text-black fw-bold mb-0 text-center">
              Envie sua mensagem anônima:
            </h4>
          </div>
          <form ref="formRegister" @submit.prevent="submitRegister">
            <div class="row d-flex">

              <div class="form-group mt-4 mb-15 mb-sm-20 mb-md-25 col-12 col-sm-12 col-lg-12 col-xl-12 col-xxl-12 col-xxxl-12">
                <label class="d-block text-black fw-semibold mb-10">
                  Whatsapp que irá receber a mensagem
                </label>
                <input
                  ref="cellphone"
                  type="text"
                  name="cellphone"
                  class="form-control shadow-none rounded-0 text-black radius-7 custom-font-size"
                  placeholder="(xx) x xxxx-xxxx"
                  @keyup="changeInformationInputs('cellphone')"
                  @input="applyMaskPhone"
                  maxlength="15"
                />
              </div>

              <div class="form-group mb-15 mb-sm-20 mb-md-25 col-12 col-sm-12 col-lg-12 col-xl-12 col-xxl-12 col-xxxl-12">
                <label class="d-block text-black fw-semibold mb-10">
                  Mensagem anônima
                </label>
                <textarea
                  ref="secret_message"
                  type="text"
                  name="secret_message"
                  rows="7"
                  class="form-control shadow-none rounded-0 text-black radius-7 custom-font-size"
                  placeholder="Digite sua mensagem aqui"
                  v-model="message"
                  :maxlength="maxLength"
                />

                <div class="character-counter">
                  {{ remainingCharacters }} caracteres restantes
                </div>
              </div>

              <h4 class="d-flex mt-4 justify-content-center">Suas informações:</h4>
              <p class="d-flex mb-4 justify-content-center">Não se preocupe, suas informações ficarão anônimas</p>
              
              <div class="form-group mb-15 mb-sm-20 mb-md-25 col-12 col-sm-12 col-lg-12 col-xl-12 col-xxl-12 col-xxxl-12">
                <label class="d-block text-black fw-semibold mb-10">
                  Digite o seu CPF
                </label>
                <input
                  ref="cpf"
                  type="text"
                  name="cpf"
                  class="form-control shadow-none rounded-0 text-black radius-7 custom-font-size"
                  placeholder="xxx.xxx.xxx-xx"
                  @keyup="changeInformationInputs('cpf')"
                  @input="applyMaskCpf"
                  maxlength="14"
                />
              </div>
              
              <div class="form-group mb-15 mb-sm-20 mb-md-25 col-12">
                <label class="d-block text-black fw-semibold mb-10">
                  Digite seu email
                </label>
                <input
                  ref="email"
                  type="email"
                  name="email"
                  class="form-control shadow-none rounded-0 text-black radius-7 custom-font-size"
                  placeholder="Ex: exemplo@gmail.com"
                  @keyup="changeInformationInputs('email')"
                />
              </div>

              <div class="form-group mb-15 mb-sm-20 mb-md-25 col-12 col-sm-12 col-lg-12 col-xl-12 col-xxl-12 col-xxxl-12">
                <label class="d-block text-black fw-semibold mb-10">
                  Crie uma senha
                </label>
                <input
                  ref="password"
                  type="password"
                  name="password"
                  class="form-control shadow-none rounded-0 text-black radius-7 custom-font-size"
                  placeholder="**************"
                  @keyup="changeInformationInputs('password')"
                />
              </div>

              <div class="form-group col-12">
                <div ref="error_password_message" class="error-password-message">
                  As senhas são diferentes !
                </div>
              </div>
              
              <div class="form-group col-12">
                <div ref="error_password_message_caracters" class="error-password-message-caracters">
                  A senha precisa ter pelo menos 1 número e 1 caracter especial !
                </div>
              </div>

              <div
                class="form-check form-check-primary mb-0 fs-md-15 fs-lg-16 text-muted lh-1 mb-15 mb-md-20 col-12"
              >
                <label class="form-check-label" for="remember-me">
                  Enviar esta mensagem implica concordar com os
                  <a href="/terms" target="_blank" class="text-decoration-none">nossos termos</a>
                </label>
              </div>

              <div class="form-group col-12">
                <button
                  class="default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 bg-success d-block w-100"
                  type="submit"
                  ref="button_submit_register"
                >
                  Enviar mensagem
                </button>
              </div>

              <div class="form-group col-12">
                <div class="message-error-register" v-if="getErrorRegister != ''">
                  {{ getErrorRegister }}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ref } from 'vue'

export default {
  name: "RegisterContent",
  data() {
    return {
      message: "",
      maxLength: 1000,
    };
  },
  computed:{
    ...mapGetters([
      'getErrorRegister'
    ]),
    remainingCharacters() {
      return this.maxLength - this.message.length;
    }
  },
  methods: {
    ...mapActions([
        "sendRegister"
    ]),
    changeInformationInputs(element){
      this.$refs[element].style.borderColor = "#dedee4";
    },
    applyMaskCpf(event) {
      const input = event.target;
      let value = input.value.replace(/\D/g, '');

      if (value.length <= 11) {
        value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      }

      input.value = value;
    },
    applyMaskPhone(event) {
      const input = event.target;
      let value = input.value.replace(/\D/g, '');

      if (value.length > 10) {
        value = value.replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
      } else {
        value = value.replace(/^(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
      }

      input.value = value;
    },
    hasLetters(str) {
      return /[a-zA-Z]/.test(str);
    },
    validateCaractersInputs(data){
      data.cellphone = data.cellphone.replace(/[!@#$(.) /-]/g, "");
      data.cpf = data.cpf.replace(/[!@#$(.) /-]/g, "");

      return data;
    },
    checkPassword(password){
      return /[0-9]/.test(password) && /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>?]/.test(password);
    },
    submitRegister(){
      const formData = new FormData(this.$refs['formRegister']);
      var data = {};
      for (let [key, val] of formData.entries()) {
        Object.assign(data, { [key]: val })
      }

      data = this.validateCaractersInputs(data);

      let error = false;
      
      if(data.secret_message == ""){
        this.$refs["secret_message"].style.borderColor = "red";
        error = true;
      }

      if(data.cellphone == "" || this.hasLetters(data.cellphone) || data.cellphone.length < 10){
        this.$refs["cellphone"].style.borderColor = "red";
        error = true;
      }
      
      if(data.cpf == "" || this.hasLetters(data.cpf) || (data.cpf.length != 11 && data.cpf.length != 14)){
        this.$refs["cpf"].style.borderColor = "red";
        error = true;
      }
      
      if(data.email == ""){
        this.$refs["email"].style.borderColor = "red";
        error = true;
      }
      
      if(data.password == ""){
        this.$refs["password"].style.borderColor = "red";
        error = true;
      }

      if(!this.checkPassword(data.password)){
        this.$refs["error_password_message_caracters"].style.display = "flex";
        error = true;
      } else {
        this.$refs["error_password_message_caracters"].style.display = "none";
      }

      if(error){
        return;
      }

      this.$refs.button_submit_register.innerHTML = "Carregando...";
      this.$refs.button_submit_register.disabled = true;

      this.sendRegister(data).then(() => {
        this.$refs.button_submit_register.innerHTML = "Criar conta";
        this.$refs.button_submit_register.disabled = false;
      });
    }
  }
};
</script>

<style scoped>
.character-counter {
  margin-top: 5px;
  font-size: 0.9em;
  color: gray;
}
</style>