import api from '../../config/axios.js';
import moment from 'moment';

const state = {
    recordings: [],
    cards: {
        total_success: 0,
        total_error: 0,
        total_time: 0,
        total_size: 0,
    },
    recordingsPagination: [],
    currentRecording: {},
    currentUrlRecording: [],
    setRecordingShowModalState: false,
    indexRecordSearch: []
}
const getters = {
    getRecordings: state => state.recordings,
    getRecordingsCards: state => state.cards,
    getRecordingsPagination: state => state.recordingsPagination,
    getCurrentRecording: state => state.currentRecording,
    getCurrentUrlRecording: state => state.currentUrlRecording,
    getRecordingShowModalState: state => state.recordingShowModalState,
    getIndexRecordSearch: state => state.indexRecordSearch,
}
const actions = {
    // BUSCANDO GRAVAÇÕES COM BASE NO FILTRO DE DATA
    async setIndexRecordSearch({ commit }, filter){
        const token = localStorage.getItem("secretadmirertoken");
        if(token != undefined && token != null && token){
        await api.axios.get(`/api/recordings/index`,  {headers: { Authorization: `Bearer ${token}` }}).catch((error) => {
            // console.log("tratar ao buscar indice de gravações", error);
        }).then(response => {
            if(response && response.data){
                commit("setIndexRecordSearch", response.data);
            }         
        });

        } else {
            window.location.href = "/login";
        }

    },
    // BUSCANDO GRAVAÇÕES COM BASE NO FILTRO DE DATA
    async setRecordings({ commit }, filter){
        commit("resetRecordings");
        const token = localStorage.getItem("secretadmirertoken");
        if(token != undefined && token != null && token){
        await api.axios.get(`/api/recordings/${filter.dateInit}/${filter.dateEnd}/${filter.name}/${filter.index}`,  {headers: { Authorization: `Bearer ${token}` }}).catch((error) => {
            // console.log("tratar erro de gravações", error);
        }).then(response => {
            if(response && response.data){
                if(response.data.table){
                    commit("setRecordingsTable", response.data.table);
                }
                if(response.data.cards){
                    commit("setRecordingsCards", response.data.cards);
                }
            }         
        });

        } else {
            window.location.href = "/login";
        }

    },
    // SETANDO GRAVAÇÃO CORRENTE
    async setCurrentRecording({ commit }, recording){
        commit('setCurrentRecording', recording);
    },
    // BUSCANDO ARQUIVO DE AUDIO
    async searchRecordingRoute({ commit }, id){
        const token = localStorage.getItem("secretadmirertoken");
        if(token != undefined && token != null && token){
        await window.axios.get(`/api/getRecording/${id}`,  {headers: { Authorization: `Bearer ${token}` }}).catch((error) => {
            // console.log("tratar erro de gravações", error);
        }).then(response => {
            if(response && response.data){
                commit("searchRecordingRoute", response.data);
            }         
        });

        } else {
            window.location.href = "/login";
        }

    },
    setRecordingShowModalState({ commit }, data){
        commit("setRecordingShowModalState", data);
    }
}
const mutations = {
    // SETANDO GRAVAÇÕES COM BASE NO FILTRO DE DATA
    setRecordingsTable: function(state, data){
        state.recordings = [];
        state.recordingsPagination = [];

        let pagination = 0;
        for(let i=0;i < data.length;i++){
            data[i].created_at = moment(data[i].created_at).format('DD/MM/YYYY HH:mm:ss');
            data[i].duration = moment.utc(data[i].duration*1000).format('HH:mm:ss');

            if(state.recordingsPagination[pagination] == undefined){
                state.recordingsPagination[pagination] = [];
            }


            if(state.recordingsPagination[pagination].length < 10){
                state.recordingsPagination[pagination].push(data[i]);
            } else {
                pagination++;
                state.recordingsPagination[pagination] = [];
                state.recordingsPagination[pagination].push(data[i]);
            }
        }
        state.recordings = data;
    },
    // SETANDO GRAVAÇÕES COM BASE NO FILTRO DE DATA
    setRecordingsCards: function(state, data){
        state.cards = data;
    },
    // SETANDO GRAVAÇÃO CORRENTE
    setCurrentRecording: function(state, data){
        state.currentRecording = data;
    },
    // SETANDO URL DO ARQUIVO DE ÁUDIO
    searchRecordingRoute: function(state, data){
        state.currentUrlRecording = data;
    },
    resetRecordings: function(state){
        state.recordings = [];
        state.recordingsPagination = [];
    },
    setRecordingShowModalState: function(state, data){
        state.recordingShowModalState = data;
    },
    setIndexRecordSearch: function(state, data){
        state.indexRecordSearch = data;
    }
}
export default {
    state,
    getters,
    actions,
    mutations
}