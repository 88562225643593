<template>
  <img
    src="../../assets/images/city-background.jpg"
    alt="contact-image"
    class="image-background-login"
  />
  <LoginForm />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import LoginForm from "../../components/Authentication/LoginForm.vue";

export default defineComponent({
  name: "LoginPage",
  components: {
    LoginForm,
  },
});
</script>