<template>
  <header class="header color-container-branco navbar navbar-expand-lg navbar-sticky navbar-stuck position-absolute navbar-sticky z-index-header">
    <div class="container px-3 d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <img class="me-2" src="../../assets/images/favicon.png" width="40" alt="Layer">
        <h4><strong>Elogio anônimo</strong></h4>
      </div>
      <i class="ph ph-list fs-30 menu-toggle" @click="toggleMenu"></i>

      <ul :class="['style-list', 'd-sm-flex', 'd-lg-flex', 'd-xl-flex', 'd-xxl-flex', { 'active': isMenuActive }]" id="mobileMenu">
        <li class="pointer d-flex align-items-center"><a class="style-link" href="#SectionBeneficios">Considerações</a></li>
        <li class="pointer d-flex align-items-center"><a class="option-login d-flex" href="/login"><i class="flaticon-user d-flex align-items-center me-2"></i> Entrar</a></li>
      </ul>
    </div>
  </header>
  
  <div>
    <section class="color-container-branco mt-50 position-relative overflow-hidden py-4">
      <div class="container pt-lg-3">
        <div class="row flex-lg-nowrap">
          <div class="col-lg-6 col-xl-5 text-center text-lg-start pt-5 mt-xl-4">
            <h1 class="display-5 pt-5 pb-2 pb-lg-3 mt-sm-2 mt-lg-5 title-home-page">Envie uma mensagem anônima no WhatsApp e surpreenda quem você gosta!</h1>
            
            <p class="fs-18 mb-4 mb-lg-5">Nosso app permite que você envie mensagens anônimas para WhatsApp daquela pessoa especial. Deixe a conversa fluir e veja onde isso pode levar vocês!</p>

            <a href="/register" class="btn btn-primary btn-lg mt-40"> 
              Comece agora
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="bx fs-xl ms-2" width="1em" height="1em" viewBox="0 0 24 24">
                <path fill="currentColor" d="m11.293 17.293l1.414 1.414L19.414 12l-6.707-6.707l-1.414 1.414L15.586 11H6v2h9.586z"></path>
              </svg>
            </a>

            <!-- <div class="pt-50 mt-xl-50">
              <h6 class="pt-xl-3 pb-3 pb-lg-4"><strong>Garantimos 100% do anonimato sobre quem está enviando a mensagem. Se você já conhece e conversa com esta pessoa e não quer se identificar, evite usar palavras que você utiliza para continuar em anonimato.</strong></h6>
            </div> -->

          </div>

          <div class="parallax mt-4">
            <div class="parallax-layer">
              <img src="../../assets/images/home/secret-admirer-3.png" alt="Layer">
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div>
    <section id="SectionBeneficios" class="color-container-branco position-relative overflow-hidden py-4">
      <div class="container mt-50">
        <h2 class="h1 text-center pb-4 pb-lg-5 fw-bold mb-40">Porque enviar uma mensagem anônima ?</h2>
    
        <div class="row mt-50">
          <div class="area-card-home col-sm-6 col-md-4 col-lg-3 text-center pb-md-2 mb-3 mb-lg-4 mt-30">
            <div class="bg-secondary rounded-circle-customize p-3 mb-4">
              <i class="color-blue d-flex fs-25 flaticon-giftbox"></i>
            </div>
            <h3 class="h5 pb-1 mb-2 fw-bold">Surpresa inesquecível</h3>
            <p class="fs-sm">Crie um momento inesperado e especial para quem você admira.</p>
          </div>

          <div class="area-card-home col-sm-6 col-md-4 col-lg-3 text-center pb-md-2 mb-3 mb-lg-4 mt-30">
            <div class="bg-secondary rounded-circle-customize p-3 mb-4">
              <i class="color-blue d-flex fs-25 flaticon-compare"></i>
            </div>
            <h3 class="h5 pb-1 mb-2 fw-bold">Quebre a rotina</h3>
            <p class="fs-sm">Uma mensagem anônima pode transformar o dia de alguém.</p>
          </div>

          <div class="area-card-home col-sm-6 col-md-4 col-lg-3 text-center pb-md-2 mb-3 mb-lg-4 mt-30">
            <div class="bg-secondary rounded-circle-customize p-3 mb-4">
              <i class="color-blue d-flex fs-25 flaticon-hot-sale"></i>
            </div>
            <h3 class="h5 pb-1 mb-2 fw-bold">Demonstre seu interesse</h3>
            <p class="fs-sm">Mostre seu carinho de maneira misteriosa e única.</p>
          </div>

          <div class="area-card-home col-sm-6 col-md-4 col-lg-3 text-center pb-md-2 mb-3 mb-lg-4 mt-30">
            <div class="bg-secondary rounded-circle-customize p-3 mb-4">
              <i class="color-blue d-flex fs-25 flaticon-lock-1"></i>
            </div>
            <h3 class="h5 pb-1 mb-2 fw-bold">Segurança no anonimato</h3>
            <p class="fs-sm">Expresse seus sentimentos sem revelar sua identidade.</p>
          </div>

          <div class="area-card-home col-sm-6 col-md-4 col-lg-3 text-center pb-md-2 mb-3 mb-lg-4 mt-30">
            <div class="bg-secondary rounded-circle-customize p-3 mb-4">
              <i class="color-blue d-flex fs-25 flaticon-communication"></i>
            </div>
            <h3 class="h5 pb-1 mb-2 fw-bold">Construa curiosidade</h3>
            <p class="fs-sm">Desperte a curiosidade de quem você gosta e veja como ela reage.</p>
          </div>

          <div class="area-card-home col-sm-6 col-md-4 col-lg-3 text-center pb-md-2 mb-3 mb-lg-4 mt-30">
            <div class="bg-secondary rounded-circle-customize p-3 mb-4">
              <i class="color-blue d-flex fs-25 flaticon-check"></i>
            </div>
            <h3 class="h5 pb-1 mb-2 fw-bold">Primeiro passo sem medo</h3>
            <p class="fs-sm">Envie uma mensagem sem a pressão de uma resposta imediata.</p>
          </div>

          <div class="area-card-home col-sm-6 col-md-4 col-lg-3 text-center pb-md-2 mb-3 mb-lg-4 mt-30">
            <div class="bg-secondary rounded-circle-customize p-3 mb-4">
              <i class="color-blue d-flex fs-25 flaticon-chat-1"></i>
            </div>
            <h3 class="h5 pb-1 mb-2 fw-bold">Prepare o terreno</h3>
            <p class="fs-sm">Abra caminho para um futuro contato sem o receio de um encontro direto.</p>
          </div>

          <div class="area-card-home col-sm-6 col-md-4 col-lg-3 text-center pb-md-2 mb-3 mb-lg-4 mt-30">
            <div class="bg-secondary rounded-circle-customize p-3 mb-4">
              <i class="color-blue d-flex fs-25 flaticon-heart-1"></i>
            </div>
            <h3 class="h5 pb-1 mb-2 fw-bold">Diverte e envolve</h3>
            <p class="fs-sm">Uma mensagem anônima pode criar um jogo divertido de adivinhação e expectativa.</p>
          </div>
        </div>
      </div>
    </section>
  </div>

  <footer class="footer pt-5 pb-4 pb-lg-5">
    <div class="container pt-lg-4">
      <div class="row pb-5">
        <div class="col-md-6 col-lg-4 mt-30">

          <p class="fs-sm pb-lg-3 mb-4">Nossa missão é oferecer o melhor serviço e proporcionar uma experiência excepcional aos nossos clientes, garantindo que suas mensagens sejam entregues com total anonimato. Nosso objetivo é ajudar nossos clientes a alcançar seus propósitos de maneira segura e discreta, promovendo conexões autênticas e relacionamentos verdadeiros. Queremos ser o ponto de partida para unir pessoas de forma criativa e dinâmica, incentivando novas possibilidades de interação.</p>

        </div>
        <div class="col-md-5 col-lg-7 col-xl-6 offset-xl-2 offset-md-1 pt-4 pt-md-1 pt-lg-0 mt-30">
          <div class="row" id="footer-links">
            <div class="col-lg-4">
              <div id="useful_links" class="d-block" is-nav="false">
                <ul class="nav flex-column pb-lg-1 mb-lg-3">
                  <li class="nav-item"><a href="#" class="nav-link d-inline-block px-0 pt-1 pb-2">Inicio</a></li>
                  <li class="nav-item"><a href="/login" class="nav-link d-inline-block px-0 pt-1 pb-2">Logar</a></li>
                  <li class="nav-item"><a href="/register" class="nav-link d-inline-block px-0 pt-1 pb-2">Registrar-se</a></li>
                  <li class="nav-item"><a href="/forgot-password" class="nav-link d-inline-block px-0 pt-1 pb-2">Recuperar senha</a></li>
                </ul>
                <ul class="nav flex-column mb-2 mb-lg-0">
                  <li class="nav-item"><a href="/terms" target="_blank" class="nav-link d-inline-block px-0 pt-1 pb-2">Termos e Condições</a></li>
                  <li class="nav-item"><a href="/privacy-policy" target="_blank" class="nav-link d-inline-block px-0 pt-1 pb-2">Política de privacidade</a></li>
                </ul>
              </div>
            </div>
            
            <div class="col-lg-5 col-xl-4 pt-2 pt-lg-0 text-center">
              <h6 class="mb-2">Contate-nos</h6>
              <a href="mailto:contato@sanvault.com.br" class="fw-medium">contato@sanvault.com.br</a>
            </div>
            </div>
          </div>
        </div>
        <p class="nav d-block fs-xs text-center text-md-start pb-2 pb-lg-0 mb-0">© 2024 Secret Admirer. All rights reserved</p>
        </div>
  </footer>


</template>

<script lang="ts">

import { defineComponent } from "vue";
import { mapGetters, mapActions } from 'vuex';

export default defineComponent({
  name: "HomePage",
  data() {
    return {
      quantidade: 0,
      tamanhoMedio: 0,
      valorFinal: "0,00",
      isMenuActive: false
    };
  },
  methods: {
    ...mapActions([
      "setServicePrice",
      "setGbFree",
    ]),
    toggleMenu() {
      this.isMenuActive = !this.isMenuActive;
    },
    formattedValue(value){
      let numero = parseFloat(value);
      return numero.toFixed(2).replace('.', ',');
    },
    calcularValorFinal() {
      const totalGB = (this.quantidade * this.tamanhoMedio) / 1024;
      const totalCurrentPrice = totalGB * this.getServicePrice;
      const valorFinalSemArredondar = Math.floor(totalCurrentPrice * 100) / 100;
      
      this.valorFinal = new Intl.NumberFormat('pt-BR', { 
        minimumFractionDigits: 2, 
        maximumFractionDigits: 2 
      }).format(valorFinalSemArredondar);
    }
  },
  mounted() {
    this.setServicePrice();
    //this.setGbFree();
  },
  computed:{
    ...mapGetters([
      "getServicePrice",
      "getGbFree"
    ])
  },
});

</script>

<style scoped>

.color-container-branco {
  background-color: #fff !important;
}

.color-container-preto {
  background-color: rgba(33, 37, 41, 1) !important;
}

.style-link{
  text-decoration: none;
  font-weight: bold;
  color: #2b2a3f;
  opacity: 0.8;
}

.style-link:hover{
  opacity: 1;
  transition: 0.2s;
}

.z-index-header{
  z-index: 99999;
}

header {
  height: 70px;
  width: 100%;
  box-shadow: 0 .3rem 1.525rem -.375rem rgba(11, 15, 25, .1), 0 .25rem .8125rem -.125rem rgba(11, 15, 25, .06);
}

header h4 {
  margin-bottom: unset;
  cursor: pointer;
}

.style-list{
  list-style: none;
  margin: unset;
}

.style-list li{
  font-size: 14pt;
  margin-right: 40px;
}

.pointer{
  cursor: pointer !important;
}

.option-login{
  background-color: #6560F0;
  color: #fff;
  padding: 5px 15px;
  border-radius: 7px;
  text-decoration: none;
}

.title-home-page{
  margin-top: 80px !important;
  padding-top: 40px !important;
  font-weight: bold;
}

.area-card-home{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rounded-circle-customize{
  border-radius: 100px;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f6ff !important;
}

.color-blue{
  color: #6366f1;
}

.custom-buttom-register-now{
  box-shadow: 1px 2px 12px -2px #000;
}

.font-icon{
  font-size: 20pt;
  color: #fff;
}

.area-icon-price{
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px;
}

.shadow-card-custom{
  box-shadow: 1px 1px 24px -7px #000;
}












/* Estilos básicos */
.header {
    background-color: #fff;
    padding: 10px 0;
    position: relative;
}

.menu-toggle {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.menu-toggle span {
    width: 25px;
    height: 3px;
    background-color: #000;
    margin: 5px 0;
}

/* Estilos para mobile */
@media (max-width: 768px) {
    .menu-toggle {
        display: flex;
    }

    .style-list {
        display: none;
        position: absolute;
        top: 60px;
        right: 0;
        left: 0;
        background-color: #fff;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 10px 0;
    }

    .style-list.active {
        display: flex; /* O menu só aparecerá quando a classe 'active' for aplicada */
    }

    .style-list li {
        margin: 10px 0;
    }
}

.parallax-layer{
  display: flex;
  justify-content: center;
}
</style>