<template>
  <NewMessage />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import NewMessage from "../../components/NewMessage/NewMessage.vue";

export default defineComponent({
  name: "NewMessagePage",
  components: {
    NewMessage,
  },
});
</script>