<template>
  <div class="loading" v-if="loading">
    <h3>Carregando...</h3>
  </div>
  <div v-for="(value, key) in getMessages" :key="key" class="card mb-25 border-0 radius-7 bg-white stats-item">
    <router-link
      :to="value.payment == 0 ? '/payment' : '/chat/'+value.id+'/'+getRealtimeChannel"
      class="link-btn text-decoration-none text-primary fw-medium fs-md-15 fs-lg-16 position-relative"
    >
      <div class="card-body p-15 p-sm-20 p-md-25 pt-lg-30 letter-spacing align-items-center d-flex">
        
        <div class="row justify-content-between">
          <div class="col-lg-5 col-md-5 flex-card-custom">
            <div
              class="icon position-relative text-success rounded-1 text-center photo-chat area-message-photo"
            >
              <i class="flaticon-user-3"></i>
            </div>

            <div class="flex-column ml-10 info text-content-card area-message-content">
              <h5 class="fw-black mb-0 me-10 lh-1">{{ value.whatsapp }}</h5>

              <span v-if="value.payment == 0" class="style-payment-pending">Pagamento pendente</span>

              <div class=" width-100 mt-2">
                <span class="fs-13 d-block text-dark-emphasis fw-bold">
                  {{ value.messages[0].message }}
                </span>
              </div>
              
              <div v-if="value.new_message_alert" class="custom-new-message">Você possui uma nova mensagem</div>
            </div>
          </div>

        </div>

      </div>
    </router-link>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters, mapActions } from 'vuex';
import Pusher from 'pusher-js';

export default defineComponent({
  name: "UploadSuccessCard",

  data: function () {
    return {
      loading: true,
      pusher: null
    };
  },
  methods: {
    ...mapActions([
        "setMessages",
        "setRealtimeChannel",
        "setPaymentModeDefault",
        "setNewMessageAlert"
    ]),
  },
  computed:{
    ...mapGetters([
      'getMessages',
      'getRealtimeChannel',
      'getPaymentModeDefault'
    ])
  },
  mounted() {
    this.setPaymentModeDefault().then(() => {

      if(this.getPaymentModeDefault.length == 0){
        window.location.href = "/payment";
      }
      this.setMessages().then(() => {
        this.loading = false;
      });
    });

    this.setRealtimeChannel().then(() => {

      this.pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
        cluster: process.env.VUE_APP_PUSHER_CLUSTER,
        useTLS: true
      });
      
      const pusher = this.pusher;
      const setNewMessageAlert = this.setNewMessageAlert;
      
      if (this.getRealtimeChannel && pusher) {
        
        this.channel = pusher.subscribe(this.getRealtimeChannel);
        
        this.messageHandler = function(data) {
          setNewMessageAlert(data);
        };
        
        this.channel.bind("new-message", this.messageHandler);
      }

    });
  },
  unmounted() {
    if (this.pusher != null && this.channel != null) {
      this.channel.unbind('new-message', this.messageHandler);
      this.pusher.unsubscribe(this.getRealtimeChannel);
      this.channel = null;
    }
  }
});
</script>


<style scoped>

.photo-chat{
  border-radius: 100px !important;
}

.text-content-card{
  display: flex;
  justify-content: center;
  width: 90%;
}


@media only screen and (max-width: 1000px) {
  .area-message-content{
    width: calc(100% - 75px);
  }
}

.loading{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.custom-new-message{
  background-color: #00a8ff;
  color: #fff;
  padding: 7px;
  border-radius: 40px;
  text-align: center;
}

.area-message-photo{
  flex: 0 0 auto !important;
}

.style-payment-pending{
  color: #fff;
  background-color: chocolate;
  border-radius: 30px;
  padding: 5px 15px;
  font-size: 16px;
  font-weight: bold;
}
</style>