<template>
  <ChatComponent />
</template>
  
<script lang="ts">
import { defineComponent } from "vue";

import ChatComponent from "../../components/Chat/ChatComponent.vue";

export default defineComponent({
  name: "ChatPage",
  components: {
    ChatComponent,
  }
});
</script>