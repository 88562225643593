import { createWebHistory, createRouter } from "vue-router";

import MessagesPage from "../pages/Messages/MessagesPage.vue";
// import PaymentPage from "../pages/Payment/PaymentPage.vue";
import FirstPaymentPage from "../pages/FirstPayment/FirstPaymentPage.vue";
import ChatPage from "../pages/Chat/ChatPage.vue";
import NewMessagePage from "../pages/NewMessage/NewMessagePage.vue";

import HomePage from "../pages/Home/HomePage.vue";
import LoginPage from "../pages/Authentication/LoginPage.vue";
import RegisterPage from "../pages/Authentication/RegisterPage.vue";
import ForgotPasswordPage from "../pages/Authentication/ForgotPasswordPage.vue";
import ForgotPasswordSendEmailPage from "../pages/Authentication/ForgotPasswordSendEmailPage.vue";
import TermsPage from "../pages/TermsPage.vue";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage.vue";
import ErrorPage from "../pages/ErrorPage.vue";

const routes = [
  {
    path: "/messages",
    name: "MessagesPage",
    component: MessagesPage,
  },
  {
    path: "/payment",
    name: "FirstPaymentPage",
    component: FirstPaymentPage,
  },
  {
    path: "/chat/:chatId/:userId",
    name: "ChatPage",
    component: ChatPage,
  },
  {
    path: "/chat/:chatId/:userId/:token",
    name: "ChatMessagePage",
    component: ChatPage,
  },
  {
    path: "/new-message",
    name: "NewMessagePage",
    component: NewMessagePage,
  },











  {
    path: "/",
    name: "HomePage",
    component: HomePage,
  },
  {
    path: "/login",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/register",
    name: "RegisterPage",
    component: RegisterPage,
  },
  {
    path: "/forgot-password",
    name: "ForgotPasswordPage",
    component: ForgotPasswordPage,
  },
  {
    path: "/send-email-forgot-password",
    name: "ForgotPasswordSendEmailPage",
    component: ForgotPasswordSendEmailPage,
  },
  {
    path: "/terms",
    name: "TermsPage",
    component: TermsPage,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicyPage",
    component: PrivacyPolicyPage,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "ErrorPage",
    component: ErrorPage
  }
];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes: routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

export default router;