import api from '../../config/axios.js';

const state = {
    metricsToday: {
        date: "",
        day_of_week_name: "",
        day_of_week: "",
        week_of_year: "",
        day_of_year: "",
        total_success: 0,
        total_error: 0,
        total_time: 0,
        total_size: 0,
        total_percentage_success: 0,
        total_percentage_error: 0,
        am: 0,
        pm: 0,
        average_time: 0,
        average_size: 0,
        bigger_time: 0,
        bigger_size: 0,
        bigger_record: "",
        compared_to_yesterday: 0
    },
    metricsHours: [],
    dashboardDayCards: {
        date: "",
        day_of_week_name: "",
        day_of_week: "",
        week_of_year: "",
        day_of_year: "",
        total_success: 0,
        total_error: 0,
        total_time: 0,
        total_size: 0,
        total_percentage_success: 0,
        total_percentage_error: 0,
        am: 0,
        pm: 0,
        average_time: 0,
        average_size: 0,
        bigger_time: 0,
        bigger_size: 0,
        bigger_record: "",
        compared_to_yesterday: 0
    },
    dashboardMonthCards: {
        date: "",
        total_success: 0,
        total_error: 0,
        total_time: 0,
        total_size: 0,
        total_percentage_success: 0,
        total_percentage_error: 0,
        am: 0,
        pm: 0,
        average_time: 0,
        average_size: 0,
        compared_to_yesterday: 0
    },
    dashboardYearCards: {
        date: "",
        total_success: 0,
        total_error: 0,
        total_time: 0,
        total_size: 0,
        total_percentage_success: 0,
        total_percentage_error: 0,
        am: 0,
        pm: 0,
        average_time: 0,
        average_size: 0
    },
    dashboardSemesterCards: {
        date: "",
        total_success: 0,
        total_error: 0,
        total_time: 0,
        total_size: 0,
        total_percentage_success: 0,
        total_percentage_error: 0,
        am: 0,
        pm: 0,
        average_time: 0,
        average_size: 0
    },
    dashboardQuarterCards: {
        date: "",
        total_success: 0,
        total_error: 0,
        total_time: 0,
        total_size: 0,
        total_percentage_success: 0,
        total_percentage_error: 0,
        am: 0,
        pm: 0,
        average_time: 0,
        average_size: 0
    },
    dashboardYearTable: [],

    dashboardDayCardsFinish: true,
    dashboardMonthCardsFinish: true,
    dashboardQuarterCardsFinish: true,
    dashboardSemesterCardsFinish: true,
    dashboardYearCardsFinish: true
}
const getters = {
    getMetricsToday: state => state.metricsToday,
    getMetricsHours: state => state.metricsHours,
    getDashboardDayCards: state => state.dashboardDayCards,
    getDashboardMonthCards: state => state.dashboardMonthCards,
    getDashboardYearCards: state => state.dashboardYearCards,
    getDashboardYearTable: state => state.dashboardYearTable,
    getDashboardSemesterCards: state => state.dashboardSemesterCards,
    getDashboardQuarterCards: state => state.dashboardQuarterCards,

    getDashboardDayCardsFinish: state => state.dashboardDayCardsFinish,
    getDashboardMonthCardsFinish: state => state.dashboardMonthCardsFinish,
    getDashboardQuarterCardsFinish: state => state.dashboardQuarterCardsFinish,
    getDashboardSemesterCardsFinish: state => state.dashboardSemesterCardsFinish,
    getDashboardYearCardsFinish: state => state.dashboardYearCardsFinish,
}
const actions = {
    // BUSCANDO MÉTRICAS DO DIA ATUAL
    async setPerformance({ commit }){

        const token = localStorage.getItem("secretadmirertoken");
        if(token != undefined && token != null && token){

        // MÉTRICAS POR DIA
            await api.axios.get('/api/metrics/day',  {headers: { Authorization: `Bearer ${token}` }}).catch((error) => {
                // console.log("tratar erro de metricas do dia atual", error);
            }).then(response => {
                if(response && response.data){
                    commit("setPerformanceDay", response.data);
                }         
            });
        
        // MÉTRICAS POR HORA
            await api.axios.get('/api/metrics/hours',  {headers: { Authorization: `Bearer ${token}` }}).catch((error) => {
                // console.log("tratar erro de metricas por hora do dia atual", error);
            }).then(response => {
                if(response && response.data){
                    commit("setPerformanceHour", response.data);
                }         
            });

        } else {
            window.location.href = "/login";
        }

    },
    // BUSCANDO MÉTRICAS DO DIA ATUAL (PERFORMANCE)
        async setDashboardDayCards({ commit }){

            const token = localStorage.getItem("secretadmirertoken");
            if(token != undefined && token != null && token){
                commit("setDashboardDayCardsFinish", false);
            // MÉTRICAS POR DIA
                await api.axios.get('/api/metrics/day',  {headers: { Authorization: `Bearer ${token}` }}).catch((error) => {
                    // console.log("tratar erro de metricas do dia atual", error);
                }).then(response => {
                    if(response && response.data){
                        commit("setDashboardDayCards", response.data);
                    }
                    commit("setDashboardDayCardsFinish", true);
                });
            
            // MÉTRICAS POR HORA
                await api.axios.get('/api/metrics/hours',  {headers: { Authorization: `Bearer ${token}` }}).catch((error) => {
                    // console.log("tratar erro de metricas por hora do dia atual", error);
                }).then(response => {
                    if(response && response.data){
                        commit("setPerformanceHour", response.data);
                    }         
                });

            } else {
                window.location.href = "/login";
            }

        },

    // BUSCANDO MÉTRICAS DO MÊS ATUAL
        async setDashboardMonthCards({ commit }){

            const token = localStorage.getItem("secretadmirertoken");
            if(token != undefined && token != null && token){
                commit('setDashboardMonthCardsFinish', false);
                await api.axios.get('/api/metrics/month', {headers: { Authorization: `Bearer ${token}` }}).catch((error) => {
                    // console.log("tratar erro de metricas do mês atual", error);
                }).then(response => {
                    if(response && response.data){
                        commit("setDashboardMonthCards", response.data);
                    }
                    commit('setDashboardMonthCardsFinish', true);
                });
            } else {
                window.location.href = "/login";
            }

        },

    // BUSCANDO MÉTRICAS DO ANO ATUAL
        async setDashboardYearCards({ commit }){

            const token = localStorage.getItem("secretadmirertoken");
            if(token != undefined && token != null && token){
                commit("setDashboardYearCardsFinish", false);
                await api.axios.get('/api/metrics/year', {headers: { Authorization: `Bearer ${token}` }}).catch((error) => {
                    // console.log("tratar erro de metricas do ano atual", error);
                }).then(response => {
                    if(response && response.data){
                        commit("setDashboardYearCards", response.data);
                    }
                    commit("setDashboardYearCardsFinish", true);
                });
            } else {
                window.location.href = "/login";
            }

        },

    // BUSCANDO MÉTRICAS DO SEMESTRE ATUAL
        async setDashboardSemesterCards({ commit }){

            const token = localStorage.getItem("secretadmirertoken");
            if(token != undefined && token != null && token){
                commit("setDashboardSemesterCardsFinish", false);
                await api.axios.get('/api/metrics/semester', {headers: { Authorization: `Bearer ${token}` }}).catch((error) => {
                    // console.log("tratar erro de metricas do ano atual", error);
                }).then(response => {
                    if(response && response.data){
                        commit("setDashboardSemesterCards", response.data);
                    }
                    commit("setDashboardSemesterCardsFinish", true);
                });
            } else {
                window.location.href = "/login";
            }

        },

    // BUSCANDO MÉTRICAS DO TRIMESTRE ATUAL
        async setDashboardQuarterCards({ commit }){

            const token = localStorage.getItem("secretadmirertoken");
            if(token != undefined && token != null && token){
                commit("setDashboardQuarterCardsFinish", false);
                await api.axios.get('/api/metrics/quarter', {headers: { Authorization: `Bearer ${token}` }}).catch((error) => {
                    // console.log("tratar erro de metricas do ano atual", error);
                }).then(response => {
                    if(response && response.data){
                        commit("setDashboardQuarterCards", response.data);
                    }
                    commit("setDashboardQuarterCardsFinish", true);
                });
            } else {
                window.location.href = "/login";
            }

        }
}

const mutations = {
    // SETANDO MÉTRICAS DO DIA ATUAL
        setPerformanceDay: function(state, data){
            state.metricsToday = data;
        },
    // SETANDO MÉTRICAS POR HORA DO DIA ATUAL
        setPerformanceHour: function(state, data){
            state.metricsHours = data;
        },
    // SETANDO MÉTRICAS DO MÊS ATUAL
        setDashboardDayCards: function(state, data){
            state.dashboardDayCards = data;
        },
    // SETANDO MÉTRICAS DO MÊS ATUAL
        setDashboardMonthCards: function(state, data){
            state.dashboardMonthCards = data;
        },
    // SETANDO MÉTRICAS DO ANO ATUAL
        setDashboardYearCards: function(state, data){
            state.dashboardYearCards = data.cards;
            state.dashboardYearTable = data.table;
        },
    // SETANDO MÉTRICAS DO SEMESTRE ATUAL
    setDashboardSemesterCards: function(state, data){
        state.dashboardSemesterCards = data;
    },
    // SETANDO MÉTRICAS DO SEMESTRE ATUAL
    setDashboardQuarterCards: function(state, data){
        state.dashboardQuarterCards = data;
    },








    setDashboardDayCardsFinish: function(state, data){
        state.dashboardDayCardsFinish = data;
    },
    setDashboardMonthCardsFinish: function(state, data){
        state.dashboardMonthCardsFinish = data;
    },
    setDashboardYearCardsFinish: function(state, data){
        state.dashboardYearCardsFinish = data;
    },
    setDashboardSemesterCardsFinish: function(state, data){
        state.dashboardSemesterCardsFinish = data;
    },
    setDashboardQuarterCardsFinish: function(state, data){
        state.dashboardQuarterCardsFinish = data;
    },
}
export default {
    state,
    getters,
    actions,
    mutations
}