import Vuex from 'vuex';

import session from './modules/session';
import messages from './modules/messages';
import recordings from './modules/recordings';
import metrics from './modules/metrics';
import realtime from './modules/realtime';
import payment from './modules/payment';
import modal from './modules/modal';

export default new Vuex.Store({

    modules: {
        session,
        messages,
        recordings,
        metrics,
        realtime,
        payment,
        modal
    }

});