const state = {
    modalUser: null,
    modalDepartment: null
}
const getters = {
    getModalUser: state => state.modalUser,
    getModalDepartment: state => state.modalDepartment
}
const actions = {
    async setModalUser({ commit }, user) {
        commit("setModalUser", user);
    },
    async setModalDepartment({ commit }, user) {
        commit("setModalDepartment", user);
    }
}
const mutations = {
    setModalUser: function(state, data) {
        state.modalUser = data;
    },
    setModalDepartment: function(state, data) {
        state.modalDepartment = data;
    }
}
export default {
    state,
    getters,
    actions,
    mutations
}