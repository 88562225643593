<template>
  <div class="card mb-25 border-0 rounded-0 bg-white text-center error-404-card radius-7">
    <div class="card-body letter-spacing style-terms-area">
      
      <h1>Política de Privacidade</h1>

      <h6 class="text-start mb-4">Esta Política de Privacidade descreve como coletamos, usamos e protegemos as informações dos usuários do nosso sistema de envio de mensagens anônimas para o WhatsApp.</h6>

      <h4>1. Dados Coletados</h4>
      <p class="text-terms">Coletamos informações como nome do usuário, email, número de telefone e o conteúdo da mensagem anônima. Também podemos coletar dados técnicos, como endereço IP e tipo de dispositivo usado, para garantir a segurança do sistema e evitar abusos. Para cumprir com as regulamentações locais, validamos o CPF do usuário quando necessário, incluindo consultas para verificar a validade do documento.</p>

      <h4>2. Uso dos Dados</h4>
      <p class="text-terms">Usamos os dados para processar e enviar mensagens de forma anônima, bem como para melhorar o serviço e fornecer suporte ao cliente. Os dados são utilizados exclusivamente para finalidades operacionais e de segurança, assegurando que o sistema funcione corretamente e que os usuários possam interagir anonimamente de forma segura.</p>

      <h4>3. Compartilhamento de Dados</h4>
      <p class="text-terms">Não compartilhamos os dados dos usuários com terceiros, exceto em situações estritamente necessárias para cumprimento de obrigações legais, em resposta a solicitações governamentais, ou para proteger nossos direitos e os dos usuários.</p>

      <h4>4. Segurança dos Dados</h4>
      <p class="text-terms">Adotamos medidas de segurança, incluindo criptografia em dados específicos e controle de acesso restrito, para proteger as informações dos usuários contra acesso não autorizado e garantir a confidencialidade das mensagens enviadas.</p>

      <h4>5. Direitos do Usuário</h4>
      <p class="text-terms">Os usuários têm o direito de acessar, corrigir e excluir seus dados. Para exercer esses direitos, entre em contato conosco através do e-mail: contato@sanvault.com.br.</p>

      <h4>6. Cookies</h4>
      <p class="text-terms">Usamos cookies para aprimorar a experiência de navegação no site e para realizar análises de uso. Os usuários podem configurar seu navegador para bloquear cookies, embora isso possa afetar algumas funcionalidades do sistema.</p>

      <h4>7. Alterações na Política</h4>
      <p class="text-terms">Podemos atualizar esta política periodicamente. Notificaremos os usuários sobre qualquer mudança significativa, e a política revisada será publicada em nosso site.</p>

      <h4>8. Contato</h4>
      <p class="text-terms">Para dúvidas sobre esta Política de Privacidade, entre em contato conosco em: contato@sanvault.com.br.</p>

    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicyPage",
};
</script>