<template>
  <FirstPayment />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import FirstPayment from "../../components/FirstPayment/FirstPayment.vue";

export default defineComponent({
  name: "FirstPaymentPage",
  components: {
    FirstPayment,
  },
});
</script>