<template>
  <div
    class="card mb-25 border-0 rounded-0 bg-white text-center error-404-card radius-7"
  >
    <div class="card-body letter-spacing style-terms-area">
      
      <h1>Termos de Uso</h1>

      <h4>1. Aceitação dos Termos</h4>
      <p class="text-terms">Ao acessar e usar nosso sistema de envio de mensagens anônimas no WhatsApp, você concorda em cumprir e estar vinculado a estes Termos de Uso. Se você não concorda com esses termos, por favor, não utilize o sistema.</p>

      <h4>2. Serviços Oferecidos</h4>
      <p class="text-terms">Nosso sistema permite que mensagens anônimas sejam enviadas para destinatários no WhatsApp, mantendo a identidade do remetente confidencial. Este sistema foi desenvolvido com o objetivo de promover interações anônimas e seguras entre admiradores secretos e seus destinatários, proporcionando uma experiência de comunicação discreta.</p>

      <h4>3. Privacidade e Anonimato</h4>
      <p class="text-terms">O remetente entende que a confidencialidade da identidade é um dos principais recursos do sistema. As informações pessoais do remetente não serão reveladas ao destinatário, a menos que o próprio remetente decida compartilhar.</p>

      <h4>4. Responsabilidade do Usuário</h4>
      <p class="text-terms">O usuário (remetente) é responsável por assegurar que o conteúdo da mensagem seja adequado e que não viole quaisquer leis ou regulamentos aplicáveis. O envio de mensagens ofensivas, assediantes, ou que promovam qualquer forma de discriminação é estritamente proibido.</p>

      <h4>5. Pagamento e Cobrança</h4>
      <p class="text-terms">O serviço é pago com base nas conversas criadas no sistema. o usuário será informado sobre os valores e métodos de pagamento antes de realizar o envio da mensagem.</p>

      <h4>6. Uso Aceitável</h4>
      <p class="text-terms">O usuário concorda em não utilizar o sistema para qualquer finalidade ilegal ou não autorizada. O usuário também concorda em não tentar interferir no funcionamento do sistema ou explorar qualquer vulnerabilidade que possa comprometer a segurança e a confidencialidade dos dados.</p>

      <h4>7. Limitação de Responsabilidade</h4>
      <p class="text-terms">Não nos responsabilizamos por qualquer dano direto ou indireto decorrente do uso ou incapacidade de uso do sistema. Isso inclui, sem limitação, perda de dados, perda de receitas, ou interrupção de comunicação. O uso do sistema é por conta e risco do usuário.</p>

      <h4>8. Cancelamento do Serviço</h4>
      <p class="text-terms">O sistema se reserva o direito de cancelar o serviço de envio de mensagens anônimas a qualquer momento, caso seja identificado qualquer uso inadequado ou violação destes Termos de Uso.</p>

      <h4>9. Alterações nos Termos de Uso</h4>
      <p class="text-terms">Reservamo-nos o direito de modificar estes Termos de Uso a qualquer momento. Quaisquer alterações serão notificadas ao usuário e entrarão em vigor imediatamente após a publicação. O uso contínuo do sistema após a notificação constitui aceitação dos novos termos.</p>

      <h4>10. Rescisão</h4>
      <p class="text-terms">Podemos suspender ou encerrar o acesso ao sistema a qualquer momento, sem aviso prévio, caso o usuário viole estes Termos de Uso.</p>

      <h4>11. Contato</h4>
      <p class="text-terms">Para qualquer dúvida ou preocupação sobre estes Termos de Uso, entre em contato conosco em contato@sanvault.com.br</p>



    </div>
  </div>
</template>

<script>
export default {
  name: "TermsPage",
};
</script>