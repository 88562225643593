<template>
  <div
    :class="[
      'sidebar-area position-fixed start-0 top-0 bg-black h-100vh transition',
      { active: stateStoreInstance.open },
    ]"
    id="sidebar-area"
  >
    <div class="logo position-absolute start-0 end-0 top-0 bg-black">
      <router-link
        to="/messages"
        class="d-flex align-items-center text-white text-decoration-none"
      >
        <!-- <img src="../../assets/images/favicon.png" alt="logo-icon" /> -->
        <img class="radius-7" src="../../assets/images/favicon2.png" width="40" alt="Layer">
        <span class="fw-bold ms-10">Menu</span>
      </router-link>
      <div class="border-bottom"></div>
      <button
        class="sidebar-burger-menu position-absolute lh-1 bg-transparent p-0 border-0"
        @click="stateStoreInstance.onChange"
      >
        <i class="ph ph-x"></i>
      </button>
    </div>
    <div class="sidebar-menu">
      <ul
        class="sidebar-navbar-nav ps-0 mb-0 list-unstyled accordion"
        id="sidebarNavAccordion"
      >

        <li @click="stateStoreInstance.onChange" class="dashboard sidebar-nav-item accordion-item bg-transparent border-0 rounded-0 mt-20" >
          <router-link to="/messages" class="accordion-button collapsed rounded-0 shadow-none bg-transparent d-block">
            <div :class="{ active: isMessagesActive }">
              <i class="flaticon-chat-1"></i>
              <span class="title">Mensagens</span>
            </div>
          </router-link>
        </li>

        <li @click="stateStoreInstance.onChange" class="pagamentos performance sidebar-nav-item accordion-item bg-transparent border-0 rounded-0">
          <router-link to="/payment" class="accordion-button collapsed rounded-0 shadow-none bg-transparent d-block">
            <div :class="{ active: isPaymentActive }">
              <i class="flaticon-credit-card"></i>
              <span class="title">Pagamentos</span>
            </div>
          </router-link>
        </li>

      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import stateStore from "../../utils/store";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "MainSidebar",
  setup() {
    const stateStoreInstance = stateStore;
    const route = useRoute();    
    
    const isMessagesActive = computed(() => route.path === '/messages');
    const isPaymentActive = computed(() => route.path === '/payment');

    return {
      stateStoreInstance,
      isMessagesActive,
      isPaymentActive,
    };
  },
});
</script>
<style scoped>
.active {
  color: #fff;
}
</style>