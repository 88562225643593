import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16cbd21d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainHeader = _resolveComponent("MainHeader")!
  const _component_MainSidebar = _resolveComponent("MainSidebar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_MainFooter = _resolveComponent("MainFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.isLogged && _ctx.currentRoute !== '/')
            ? (_openBlock(), _createBlock(_component_MainHeader, { key: 0 }))
            : _createCommentVNode("", true),
          (_ctx.isLogged && _ctx.currentRoute !== '/')
            ? (_openBlock(), _createBlock(_component_MainSidebar, { key: 1 }))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(["main-content d-flex flex-column transition overflow-hidden", {'center-main-content': !_ctx.isLogged, 'justify-content-start validate-home-page': _ctx.currentRoute === '/'}])
          }, [
            _createVNode(_component_router_view),
            (_ctx.isLogged && _ctx.currentRoute !== '/')
              ? (_openBlock(), _createBlock(_component_MainFooter, { key: 0 }))
              : _createCommentVNode("", true)
          ], 2)
        ]))
      : _createCommentVNode("", true)
  ]))
}