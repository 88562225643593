<template>
  <img
    src="../../assets/images/city-background.jpg"
    alt="contact-image"
    class="image-background-login"
  />
  <ForgotPasswordSendEmail />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ForgotPasswordSendEmail from "../../components/Authentication/ForgotPasswordSendEmail.vue";

export default defineComponent({
  name: "ForgotPasswordPage",
  components: {
    ForgotPasswordSendEmail,
  },
});
</script>