<template>
  <div class="row">
    <div class="col-12">
      <div
        class="card mb-25 border-0 rounded-0 bg-white checkout-box letter-spacing radius-7"
      >
        <div class="card-body">

          <div class="col-12">
            <div
              class="card mb-25 border-0 rounded-0 bg-white order-summary-box letter-spacing radius-7 container-value-price"
            >
              <div class="card-head bg-white d-flex align-items-center card-checkout-title">
                <div class="d-flex">
                  <i class="flaticon-form font-22 color-font-general"></i>
                  <h5 class="mb-0 fw-bold text-black ms-10 ms-md-15 font-16 color-font-general">
                    Valor ({{ getSummary.secret_messages_pending_payment }} conversa)
                  </h5>
                </div>
                <strong>R$ {{ getSummary.contracted_value }}</strong>
              </div>
              <div class="card-body custom-padding-value">
                <ul class="order-summary-list ps-0 mb-0 list-unstyled">
                  <li class="d-flex align-items-center justify-content-between checkout-summary-price">
                    <span class="d-block text-paragraph fw-medium">
                      O valor acima é cobrado apenas ao iniciar uma nova conversa ou quando há pagamentos pendentes. As conversas já pagas não têm custo adicional e você pode enviar quantas mensagens quiser dentro delas, até que a conversa expire.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <form @submit.prevent="submitForm">

            <!-- OPÇÕES DE PAGAMENTO -->
            <ul
              class="nav nav-tabs bg-white border-0 rounded-0 radius-7"
              id="myTab"
              role="tablist"
            >

              <li class="nav-item" role="presentation">
                <button
                  class="nav-link custom-font-size fw-bold position-relative border-0 rounded-0 d-block w-100 active radius-7"
                  id="payment-method-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#payment-method-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="payment-method-tab-pane"
                  aria-selected="true"
                >
                  <i class="flaticon-credit-card"></i>
                  Cartão de crédito
                </button>
              </li>

              <li class="nav-item" role="presentation">
                <button
                  class="nav-link custom-font-size fw-bold position-relative border-0 rounded-0 d-block w-100 radius-7"
                  id="place-order-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#place-order-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="place-order-tab-pane"
                  aria-selected="false"
                >
                  <i class="flaticon-atm"></i>
                  PIX
                </button>
              </li>

            </ul>

            <!-- PAGAMENTO NO CRÉDITO -->
            <div
              class="tab-content mt-4"
              id="myTabContent"
            >
              <div
                class="tab-pane fade show active"
                id="payment-method-tab-pane"
                role="tabpanel"
                tabindex="0"
              >
                <div id="paymentAccordion">
                  <div class="accordion-item rounded-0 radius-7 p-2">

                    <p class="text-muted accepted-cards">
                      Aceitamos apenas cartões <strong>MasterCard</strong> e <strong>Visa</strong>.
                    </p>
                    
                    <h4 class="mb-20 font-12 mt-4">Preencha as informações abaixo para confirmar o pagamento via cartão de crédito:</h4>
                    <div>
                      <div class="style-stripe card-element custom-font-size" id="card-element"></div>

                      <div id="card-errors" role="alert" style="color: red; font-size: 14px; margin-top: 10px;"></div>

                      <!-- aqui começa a parte de endereço de cobrança -->

                      <div v-if="getSummary.address_enabled">
                        <h5 class="mb-4 text-center">Precisamos de um endereço de cobrança:</h5>
                        <div class="row">
                          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl col-xxl-6">
                            <label class="text-black fw-semibold mb-10" for="cep">CEP</label>
                            <input
                              class="form-control shadow-none rounded-0 text-black radius-7 custom-font-size"
                              type="text"
                              name="cep"
                              id="cep"
                              ref="cepField"
                              v-model="form.cep"
                              @input="applyMaskCep"
                              @keyup="fetchAddress"
                              maxlength="9"
                              placeholder="Digite o CEP"
                              required
                            />
                            <label ref="cepFieldAlert" class="text-red mb-10 ms-1 d-none-custom">O cep é um campo obrigatório</label>
                          </div>
                          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl col-xxl-6">
                            <label class="text-black fw-semibold mb-10" for="street">Rua:</label>
                            <input
                              class="form-control shadow-none rounded-0 text-black radius-7 custom-font-size"
                              type="text"
                              id="street"
                              ref="streetField"
                              v-model="form.street"
                              placeholder="Rua"
                              disabled="true"
                            />
                          </div>
                          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl col-xxl-6 mt-4">
                            <label class="text-black fw-semibold mb-10" for="neighborhood">Bairro:</label>
                            <input
                              class="form-control shadow-none rounded-0 text-black radius-7 custom-font-size"
                              type="text"
                              id="neighborhood"
                              ref="neighborhoodField"
                              v-model="form.neighborhood"
                              placeholder="Bairro"
                              disabled="true"
                            />
                          </div>
                          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl col-xxl-6 mt-4">
                            <label class="text-black fw-semibold mb-10" for="city">Cidade:</label>
                            <input
                              class="form-control shadow-none rounded-0 text-black radius-7 custom-font-size"
                              type="text"
                              id="city"
                              ref="cityField"
                              v-model="form.city"
                              placeholder="Cidade"
                              disabled="true"
                            />
                            <label ref="cityFieldAlert" class="text-red mb-10 ms-1 d-none-custom">A cidade é um campo obrigatório</label>
                          </div>
                          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl col-xxl-6 mt-4">
                            <label class="text-black fw-semibold mb-10" for="state">Estado:</label>
                            <input
                              class="form-control shadow-none rounded-0 text-black radius-7 custom-font-size"
                              type="text"
                              id="state"
                              ref="stateField"
                              v-model="form.state"
                              placeholder="Estado"
                              disabled="true"
                            />
                            <label ref="stateFieldAlert" class="text-red mb-10 ms-1 d-none-custom">O estado é um campo obrigatório</label>
                          </div>
                          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl col-xxl-6 mt-4 mb-4">
                            <label class="text-black fw-semibold mb-10" for="number">Número:</label>
                            <input
                              class="form-control shadow-none rounded-0 text-black radius-7 custom-font-size"
                              type="text"
                              id="number"
                              ref="numberField"
                              @keyup="fetchNumber"
                              v-model="form.number"
                              placeholder="Número"
                              required
                            />
                            <label ref="numberFieldAlert" class="text-red mb-10 ms-1 d-none-custom">O número é um campo obrigatório</label>
                          </div>
                        </div>
                      </div>

                      <!-- aqui termina a parte de endereço de cobrança -->

                      <div class="text-center">
                        
                        <button ref="buttonConfirmPaymentStripe" class="default-btn transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-35 pe-md-35 rounded-1 custom-font-size bg-success" type="submit">
                          Confirmar pagamento
                          <!-- <i class="flaticon-right-arrow position-relative ms-5 top-2"></i> -->
                          <i class="flaticon-chevron position-relative ms-5 top-2"></i>
                        </button>
                        
                        <div v-if="getErrorPayment != ''" class="mt-40 alert alert-danger d-flex align-items-center fs-md-15 fs-lg-16" role="alert">
                          <i class="flaticon-spam lh-1 fs-20 position-relative top-1 me-8"></i>
                          {{ getErrorPayment }}
                        </div>
                        
                        <div v-if="getSuccessPayment != ''" class="mt-40 alert alert-success d-flex align-items-center fs-md-15 fs-lg-16" role="alert">
                          <i class="flaticon-party lh-1 fs-20 position-relative top-1 me-8"></i>
                          {{ getSuccessPayment }}
                        </div>
                          
                      </div>
                    </div>
                      
                    </div>
                  </div>
                </div>

                <div
                class="tab-pane fade"
                id="place-order-tab-pane"
                role="tabpanel"
                tabindex="0"
                >
                
                <!-- aqui começa a parte de endereço de cobrança -->
                <h5 v-if="!getSummary.address_enabled" class="mb-20 font-12 mt-4">Para pagar via pix, clique no botão abaixo:</h5>
                
                <div v-if="getSummary.address_enabled">
                  <h5 class="mb-20 font-12 mt-4">Precisamos de um endereço de cobrança:</h5>
                  <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl col-xxl-6">
                      <label class="text-black fw-semibold mb-10" for="cep">CEP</label>
                      <input
                        class="form-control shadow-none rounded-0 text-black radius-7 custom-font-size"
                        type="text"
                        id="cep2"
                        ref="cep2Field"
                        v-model="form.cep"
                        @input="applyMaskCep"
                        @keyup="fetchAddress"
                        maxlength="9"
                        placeholder="Digite o CEP"
                        required
                      />
                      <label ref="cep2FieldAlert" class="text-red mb-10 ms-1 d-none-custom">O cep é um campo obrigatório</label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl col-xxl-6">
                      <label class="text-black fw-semibold mb-10" for="street">Rua:</label>
                      <input
                        class="form-control shadow-none rounded-0 text-black radius-7 custom-font-size"
                        type="text"
                        id="street2"
                        ref="street2Field"
                        v-model="form.street"
                        placeholder="Rua"
                        disabled="true"
                      />
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl col-xxl-6 mt-4">
                      <label class="text-black fw-semibold mb-10" for="neighborhood">Bairro:</label>
                      <input
                        class="form-control shadow-none rounded-0 text-black radius-7 custom-font-size"
                        type="text"
                        id="neighborhood2"
                        ref="neighborhood2Field"
                        v-model="form.neighborhood"
                        placeholder="Bairro"
                        disabled="true"
                      />
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl col-xxl-6 mt-4">
                      <label class="text-black fw-semibold mb-10" for="city">Cidade:</label>
                      <input
                        class="form-control shadow-none rounded-0 text-black radius-7 custom-font-size"
                        type="text"
                        id="city2"
                        ref="city2Field"
                        v-model="form.city"
                        placeholder="Cidade"
                        disabled="true"
                      />
                      <label ref="city2FieldAlert" class="text-red mb-10 ms-1 d-none-custom">A cidade é um campo obrigatório</label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl col-xxl-6 mt-4">
                      <label class="text-black fw-semibold mb-10" for="state">Estado:</label>
                      <input
                        class="form-control shadow-none rounded-0 text-black radius-7 custom-font-size"
                        type="text"
                        id="state2"
                        ref="state2Field"
                        v-model="form.state"
                        placeholder="Estado"
                        disabled="true"
                      />
                      <label ref="state2FieldAlert" class="text-red mb-10 ms-1 d-none-custom">O estado é um campo obrigatório</label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl col-xxl-6 mt-4 mb-4">
                      <label class="text-black fw-semibold mb-10" for="number">Número:</label>
                      <input
                        class="form-control shadow-none rounded-0 text-black radius-7 custom-font-size"
                        type="text"
                        id="number2"
                        ref="number2Field"
                        @keyup="fetchNumber"
                        v-model="form.number"
                        placeholder="Número"
                        required
                      />
                      <label ref="number2FieldAlert" class="text-red mb-10 ms-1 d-none-custom">O número é um campo obrigatório</label>
                    </div>
                  </div>
                </div>

                <!-- aqui termina a parte de endereço de cobrança -->
                <div id="paymentAccordion" class="mt-4">
                  <div class="accordion-item rounded-0 radius-7 text-center">
                    <button type="button" ref="buttonConfirmPaymentMercadoPago" @click="checkoutPixPayment()" class="default-btn transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-35 pe-md-35 rounded-1 custom-font-size bg-success">
                      Pagar com PIX
                      <i class="flaticon-chevron position-relative ms-5 top-2"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
                    
          </form>



        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: "CheckoutContent",
  components: {
  },
  data() {
    return {
      stripe: null,
      cardElement: null,
      form: {
        name: '',
        email: '',
        company_id: '',
        cep: "",
        street: "",
        neighborhood: "",
        city: "",
        state: "",
        number: ""
      }
    };
  },
  async mounted() {
    this.setSummary();

    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY);
    const elements = this.stripe.elements();

    var card = elements.create("card", {
      hidePostalCode: true,
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    });

    card.mount("#card-element");
    this.cardElement = card;
    this.cardElement.mount('#card-element');

  },
  computed:{
    ...mapGetters([
      "getSuccessPayment",
      "getErrorPayment",
      "getSummary"
    ])
  },
  methods: {
    ...mapActions([
      "setResetErrorPayment",
      "setProcessSubscriptionPayment",
      "setProcessSubscriptionFirstPayment",
      "setCheckoutPixFirstPayment",
      "setCheckoutPixPayment",
      "setSummary"
    ]),
    fetchNumber(){
      this.$refs.numberFieldAlert.style.display = "none";
      this.$refs.number2FieldAlert.style.display = "none";
    },
    async fetchAddress() {
      this.$refs.cepFieldAlert.style.display = "none";
      if (this.form.cep.length !== 9) {
        return;
      }
      
      try {
        const response = await fetch(`https://viacep.com.br/ws/${this.form.cep.replace(/-/g, "")}/json/`);
        const data = await response.json();
        if (data.erro) {
          this.$refs.streetField.disabled = false;
          this.$refs.neighborhoodField.disabled = false;
          this.$refs.cityField.disabled = false;
          this.$refs.stateField.disabled = false;
          return;
        }
        this.form.street = data.logradouro || "";
        this.form.neighborhood = data.bairro || "";
        this.form.city = data.localidade || "";
        this.form.state = data.uf || "";

        this.$refs.streetField.disabled = true;
        this.$refs.neighborhoodField.disabled = true;
        this.$refs.cityField.disabled = true;
        this.$refs.stateField.disabled = true;
      } catch (error) {
        alert("Erro ao buscar o CEP. Tente novamente.");
        console.error(error);
      }
    },
    checkoutPixPayment(){
      if(this.$refs.cep2Field != null && this.$refs.cep2Field.value == ""){
        this.$refs.cep2FieldAlert.style.display = "block";
        return;
      }

      if(this.$refs.city2Field != null && this.$refs.city2Field.value == ""){
        this.$refs.city2FieldAlert.style.display = "block";
        return;
      }

      if(this.$refs.state2Field != null && this.$refs.state2Field.value == ""){
        this.$refs.state2FieldAlert.style.display = "block";
        return;
      }
      
      if(this.$refs.number2Field != null && this.$refs.number2Field.value == ""){
        this.$refs.number2FieldAlert.style.display = "block";
        return;
      }

      const buttonConfirmPaymentMercadoPago = this.$refs.buttonConfirmPaymentMercadoPago;
      buttonConfirmPaymentMercadoPago.disabled = true;
      buttonConfirmPaymentMercadoPago.innerHTML = "Processando pagamento..."
      
      const data = {};
      
      if(this.$refs.cep2Field != null){
        data.cep = this.$refs.cep2Field.value;
        data.street = this.$refs.street2Field.value;
        data.neighborhood = this.$refs.neighborhood2Field.value;
        data.city = this.$refs.city2Field.value;
        data.state = this.$refs.state2Field.value;
        data.number = this.$refs.number2Field.value;
      }

      this.setCheckoutPixFirstPayment(data).then(() => {
        buttonConfirmPaymentMercadoPago.disabled = false;
        buttonConfirmPaymentMercadoPago.innerHTML = "Confirmar pagamento"
      });
    },
    changeInformationInputs(element){
      this.$refs[element].style.borderColor = "#dedee4";
    },
    applyMaskCep(event) {
      const input = event.target;
      let value = input.value.replace(/\D/g, '');

      if (value.length > 5) {
        value = value.replace(/^(\d{5})(\d)/, '$1-$2');
      }

      input.value = value; 
    },
    applyMaskPhone(event) {
      const input = event.target;
      let value = input.value.replace(/\D/g, '');

      if (value.length > 10) {
        value = value.replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
      } else {
        value = value.replace(/^(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
      }

      input.value = value;
    },
    async submitForm() {
      // Verificação de campos obrigatórios
      if (this.$refs.cepField !== undefined && this.$refs.cepField.value === "") {
        this.$refs.cepFieldAlert.style.display = "block";
        return;
      }

      if (this.$refs.cityField !== undefined && this.$refs.cityField.value === "") {
        this.$refs.cityFieldAlert.style.display = "block";
        return;
      }

      if (this.$refs.stateField !== undefined && this.$refs.stateField.value === "") {
        this.$refs.stateFieldAlert.style.display = "block";
        return;
      }

      if (this.$refs.numberField !== undefined && this.$refs.numberField.value === "") {
        this.$refs.numberFieldAlert.style.display = "block";
        return;
      }

      const buttonConfirmPaymentStripe = this.$refs.buttonConfirmPaymentStripe;
      const errorContainer = document.getElementById('card-errors'); // Contêiner para mensagens de erro

      buttonConfirmPaymentStripe.disabled = true;
      buttonConfirmPaymentStripe.innerHTML = "Processando pagamento...";

      try {
        // Limpar mensagens de erro anteriores
        this.setResetErrorPayment();
        errorContainer.textContent = ""; // Limpa mensagens antigas do contêiner

        // Criar token do cartão
        const { token, error: errorCardElement } = await this.stripe.createToken(this.cardElement);

        let cardNumber = "";
        if (token && token.card && token.card.last4) {
          cardNumber = token.card.last4;
        }

        // Criar método de pagamento
        const { paymentMethod, error: errorCreatePayment } = await this.stripe.createPaymentMethod({
          type: 'card',
          card: this.cardElement,
          billing_details: {
            name: "",
          },
        });

        // Exibir mensagens de erro do Stripe, se houver
        if (errorCardElement) {
          errorContainer.textContent = errorCardElement.message;
          buttonConfirmPaymentStripe.disabled = false;
          buttonConfirmPaymentStripe.innerHTML = "Confirmar pagamento";
          return;
        }

        if (errorCreatePayment) {
          errorContainer.textContent = errorCreatePayment.message;
          buttonConfirmPaymentStripe.disabled = false;
          buttonConfirmPaymentStripe.innerHTML = "Confirmar pagamento";
          return;
        }

        // Nenhum erro: preparar dados para envio
        const data = {
          cardCreated: paymentMethod.id,
          cardNumber: cardNumber,
        };

        // Adicionar dados de endereço, se presentes
        if (
          this.$refs.cepField &&
          this.$refs.stateField &&
          this.$refs.cityField &&
          this.$refs.streetField &&
          this.$refs.neighborhoodField &&
          this.$refs.numberField
        ) {
          data.cep = this.$refs.cepField.value;
          data.state = this.$refs.stateField.value;
          data.city = this.$refs.cityField.value;
          data.street = this.$refs.streetField.value;
          data.neighborhood = this.$refs.neighborhoodField.value;
          data.number = this.$refs.numberField.value;
        }

        // Enviar dados para o servidor
        await this.setProcessSubscriptionFirstPayment(data);
        buttonConfirmPaymentStripe.disabled = false;
        buttonConfirmPaymentStripe.innerHTML = "Confirmar pagamento";

      } catch (error) {
        // Exibir mensagem de erro genérica
        console.error(error); // Para depuração no console
        errorContainer.textContent = "Ocorreu um erro inesperado. Por favor, tente novamente.";
        buttonConfirmPaymentStripe.disabled = false;
        buttonConfirmPaymentStripe.innerHTML = "Confirmar pagamento";
      }
    }

  }
};
</script>
<style scoped>
/* Estilo para o formulário */


/* Estilo para o elemento do cartão */
.stripe-card-element {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 16px;
}

/* Estilos para estados do elemento do cartão */
.stripe-card-element input {
  font-size: 16px;
}

.stripe-card-element .StripeElement--invalid {
  border-color: #fa755a;
}

.stripe-card-element .StripeElement--focus {
  border-color: #4CAF50;
}

/* Estilo específico para o campo de número do cartão */
.InputElement {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 16px;
  background-color: #45a049 !;
}

.InputElement:focus {
  border-color: #4CAF50;
  outline: none;
}

.InputElement--invalid {
  border-color: #fa755a;
}

.InputElement--focus {
  border-color: #4CAF50;
}

.InputElement{
  background-color: #45a049;
}



.card-element {
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

#card-errors {
  color: #fa755a;
}

.card-body{
  padding: 30px !important;
}

.custom-padding-value{
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.card-checkout-title{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.d-none-custom{
  display: none;
}

</style>