<template>
  <div class="row justify-content-center">
    <div class="col-md-10 col-lg-8 col-xl-9 col-xxl-8 col-xxxl-4 maxWidthContainer">
      <div class="card border-0 rounded-0 bg-white authentication-card border-radius-10">
        <div class="card-body letter-spacing">
          <div class="d-flex align-items-center flex-column">
            <img class="me-2" src="../../assets/images/favicon.png" width="80" alt="Layer">
            <h4 class="text-black fw-bold mb-0 text-center">
              Faça login em sua conta
            </h4>
          </div>

          <form>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Email
              </label>
              <input
                ref="email"
                type="email"
                class="form-control shadow-none rounded-0 text-black radius-7 custom-font-size"
                placeholder="teste@gmail.com"
                required
              />
            </div>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Senha
              </label>
              <input
                ref="password"
                type="password"
                class="form-control shadow-none rounded-0 text-black radius-7 custom-font-size"
                placeholder="**************"
                required
              />
            </div>

            <div v-if="messageError" class="alert alert-danger fs-md-15 fs-lg-16 message-error-login" role="alert">
              {{ messageError }}
            </div>

            <div
              class="d-flex align-items-center justify-content-between mb-15 mb-md-20"
            >
              <router-link
                to="/forgot-password"
                class="forgot-password-btn fs-md-15 fs-lg-16 text-decoration-none position-relative text-primary"
              >
                Esqueci minha senha
              </router-link>
            </div>
            <button
            @click="actionLogin"
              class="default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 bg-success d-block w-100"
              type="submit"
              ref="buttonSubmitLogin"
            >
              Entrar
            </button>
            
            <span
              class="d-block text-muted text-center mt-15 mt-md-30 mb-12 mb-md-20 fs-md-15 fs-lg-16"
            >
              Não possui uma conta?
            </span>
            <router-link
              to="/register"
              class="default-btn with-border transition fw-medium rounded-1 fs-md-15 fs-lg-16 d-block w-100 text-decoration-none text-center"
            >
              Criar conta
            </router-link>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../config/axios.js';

export default {
  name: "LoginForm",
  data () {
      return {
          dataValue: true,
          errorInputs: false,
          messageError: ""
      }
  },
  methods: {
    showMessage(message) {
      this.messageError = message;
      setTimeout(() => {
        this.messageError = null;
      }, 3000);
    },
    actionLogin(){
          event.preventDefault();

          if(this.$refs.email.value == ""){
            this.showMessage("o campo e-mail não está preenchido!");
            return;
          }

          if(!this.$refs.email.value.includes("@")){
            this.showMessage("o campo e-mail não é válido!");
            return;
          }
          
          if(this.$refs.password.value == ""){
            this.showMessage("o campo Senha não está preenchido!");
            return;
          }

          if(this.$refs.password.value.length < 8){
            this.showMessage("A senha deve conter pelo menos 8 caracteres!");
            return;
          }

          let loginBody = {
            "userEmail": this.$refs.email.value,
            "userPassword": this.$refs.password.value
          }

          this.$refs.buttonSubmitLogin.disabled = true;
          this.$refs.buttonSubmitLogin.innerHTML = "Carregando...";

          api.axios.post(`/api/login`, loginBody).then(function(response){
            if(response.status == 200){
              localStorage.setItem("secretadmirertoken", response.data.token);
              window.location.href = "/";
            }
            this.$refs.buttonSubmitLogin.innerHTML = "Entrar";
            this.$refs.buttonSubmitLogin.disabled = false;
          }).catch(_error => {
            if(_error.response && _error.response.data){
              this.showMessage(_error.response.data);
            }
            this.$refs.buttonSubmitLogin.innerHTML = "Entrar";
            this.$refs.buttonSubmitLogin.disabled = false;
          });
      }
  }
};
</script>