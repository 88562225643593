import { reactive } from "vue";

interface StateStore {
  open: boolean;
  onChange: () => void;
}

const stateStore: StateStore = reactive({
  open: false,
  onChange ()  {
    if (window.matchMedia("(max-width: 1199px)").matches) {
      stateStore.open = !stateStore.open
    }
  },
})

export default stateStore