<template>
  <LogoutModal />
  <header
    :class="[
      'header-area bg-white text-center text-md-start pt-15 pb-15 ps-15 pe-15 ps-md-20 pe-md-20 pe-lg-30 transition mb-25 position-fixed radius-7',
      { sticky: isSticky },
    ]"
    id="header"
  >
    <div class="row align-items-center flex-row justify-content-between">
      <div class="col-3 col-xl-4 col-lg-3 col-md-3 col-sm-3">
        <div
          class="header-left-side justify-content-center justify-content-md-start d-flex align-items-center"
        >
          <button
            class="header-burger-menu transition position-relative lh-1 bg-transparent p-0 border-0"
            id="header-burger-menu"
            @click="stateStoreInstance.onChange"
          >
            <i class="flaticon-menu-3"></i>
          </button>
        </div>
      </div>

      <div class="col-3 col-xl-4 col-lg-3 col-md-3 col-sm-3">
        <div class="profile-dropdown d-flex justify-content-end">
  
            <button
              class="text-end fs-14 text-black-emphasis d-flex align-items-center p-0 position-relative bg-transparent border-0 transition lh-1"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span class="title d-lg-block">
                <span class="d-block fw-bold">{{ getConfigurations.name }}</span>
                <span class="text-body-emphasis fw-semibold fs-13">Admin</span>
              </span>
            </button>

            <div class="dropdown dropdown-menu rounded-0 bg-white border-0 end-0 ps-10 radius-7">
              <ul class="ps-0 mb-0 list-unstyled dropdown-body">
                <li
                  class="pointer text-body-secondary fw-semibold transition position-relative"
                  data-bs-toggle="modal" data-bs-target="#verticallyCenteredModal"
                >
                  <i class="flaticon-logout"></i>
                  Deslogar
                </li>
              </ul>
            </div>

          </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";

import stateStore from "../../utils/store";
import LogoutModal from "../Authentication/LogoutModal.vue";
import { mapGetters } from 'vuex';

export default defineComponent({
  name: "MainHeader",
  components: {
    LogoutModal
  },
  methods: {},
  setup() {
    const stateStoreInstance = stateStore;
    const isSticky = ref(false);

    onMounted(() => {
      window.addEventListener("scroll", () => {
        let scrollPos = window.scrollY;
        isSticky.value = scrollPos >= 100;
      });
    });

    return {
      isSticky,
      stateStoreInstance,
    };
  },
  computed:{
    ...mapGetters([
      'getConfigurations'
    ])
  },
});
</script>