<template>
  
  <router-link
    to="/new-message"
    class="d-flex align-items-center text-decoration-none text-black fs-md-15 fs-lg-16"
  >
    <span class="button-new-message mb-4 text-center col-12 col-sm-3 col-md-3 "><i class="flaticon-edit"></i> Criar nova conversa</span>
  </router-link>

  <div class="row">
    <div class="col-xxl-12">
      <h3 class="text-center mb-4">Conversas:</h3>
      <MessagesComponent />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapActions } from 'vuex';
import MessagesComponent from "../../components/Messages/MessagesComponent.vue";

export default defineComponent({
  name: "RealtimePage",
  components: {
    MessagesComponent
  },
  methods: {
      ...mapActions([
          "resetAllChats"
      ]),
  },
  unmounted(){
    this.resetAllChats();
  }
});
</script>
<style scoped>
.button-new-message{
  background-color: #9c88ff;
  color: #fff;
  padding: 10px;
  border-radius: 7px;
}
</style>