const axios = require('axios');
axios.defaults.baseURL = 'https://elogioanonimo.com.br';
//axios.defaults.baseURL = '/';

//if(window.location.pathname.startsWith("https://elogioanonimo.com.br")){
    axios.get('https://elogioanonimo.com.br/api/token')
    .then(response => {
        axios.defaults.headers.common['X-CSRF-TOKEN'] = response.data.token;
    })
    .catch(error => {
        console.error('Erro ao obter o CSRF token:', error);
    });
//} else {
 //   axios.get('https://www.elogioanonimo.com.br/api/token')
  //  .then(response => {
   //     axios.defaults.headers.common['X-CSRF-TOKEN'] = response.data.token;
    //})
    //.catch(error => {
     //   console.error('Erro ao obter o CSRF token:', error);
    //});
//}


axios.defaults.headers.common['Content-Type'] = 'application/json';
//axios.defaults.baseURL = (window.origin == 'http://localhost:8080') ? 'http://localhost:3333/' : 'http://192.168.200.221:3333/';

//const cdn = () => (window.origin == 'http://localhost:8080') ? 'http://localhost:3333/corecdn?image=' : 'http://192.168.200.221:3333/corecdn?image=';

module.exports = {
    axios
}
